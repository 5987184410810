import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { PaymentDto } from './dto/paymentDto';

export class PaymentService extends CrudServiceBase<PaymentDto> {
    constructor() {
        super(Endpoint.Payment);
        this.internalHttp = httpApi;
    }

    public async getPaymentMethods(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<PaymentDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom('GetPaymentMethods'), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getPaymentPlans(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<PaymentDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom('GetPaymentPlans'), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getPaymentTypes(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<PaymentDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom('GetPaymentTypes'), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new PaymentService();