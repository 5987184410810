import * as React from 'react';
import { addDays, DatePicker, DayOfWeek, DirectionalHint, Icon, IDatePickerStrings, Shimmer, TooltipHost } from '@fluentui/react';
import { addYears } from '@fluentui/react';
import { L } from '../../lib/abpUtility';
import { myTheme } from '../../styles/theme';
import { LabelComponent } from './labelComponent';
import { LabelContainerComponent } from './labelContainerComponent';
import { getShimmerStyles } from '../../utils/stylesUtils';
import { gnInputIconStyles } from '../../styles/gnInputIconStyles';
import moment from 'moment';

const today: Date = new Date(Date.now());
const minDate: Date = addYears(today, -120);
const maxDate: Date = addYears(today, 1);

export interface IDatePickerBaseProps {
    id?: string | undefined;
    label: string;
    customClassNames?: string;
    placeholder?: string | undefined;
    ariaLabel?: string | undefined;
    disabled?: boolean | undefined;
    required?: boolean | undefined;
    value?: string | undefined;
    onChange?: (value: string | undefined) => void;
    initial?: Date | undefined;
    shouldBeValidated?: boolean | undefined;
    isDataLoaded?: boolean;
    validationData?: any;
    tooltipText?: string;
    labelContainerCustomStyles?: any;
    customLabelStyles?: any;
    customInputWidth?: string;
    errorMessage?: string;
    doNotFormatResult?: boolean;
}

export interface IDatePickerBaseState {
    firstDayOfWeek?: DayOfWeek;
    value?: Date | null;
}

export class DatePickerBase extends React.Component<IDatePickerBaseProps, IDatePickerBaseState> {
    constructor(props: IDatePickerBaseProps) {
        super(props);

        this.state = {
            firstDayOfWeek: DayOfWeek.Monday,
        };
    }

    componentDidMount() {
        try {
            if (this.props.value) {
                this.setState({ value: this._onParseDateFromString(this.props.value!) })
                if (this.props.onChange)
                    this.props.onChange(this.props.value)
            }
        } catch (error) {
            console.error(error);
        }
    }

    private getDatePickerStrings(): IDatePickerStrings {
        return {
            months: [L('January'),L('February'),L('March'),L('April'),L('May'),L('June'),L('July'),L('August'),L('September'),L('October'),L('November'),L('December')],
            shortMonths: [L('Jan'), L('Feb'), L('Mar'), L('Apr'), L('May'), L('Jun'), L('Jul'), L('Aug'), L('Sep'), L('Oct'), L('Nov'), L('Dec')],
            days: [L('Sunday'), L('Monday'), L('Tuesday'), L('Wednesday'), L('Thursday'), L('Friday'), L('Saturday')],
            shortDays: [L('Su'), L('Mo'), L('Tu'), L('We'), L('Th'), L('Fr'), L('Sa')],
            goToToday: L('Go to today'),
            prevMonthAriaLabel: L('Go to previous month'),
            nextMonthAriaLabel: L('Go to next month'),
            prevYearAriaLabel: L('Go to previous year'),
            nextYearAriaLabel: L('Go to next year'),
            closeButtonAriaLabel: L('Close date picker'),
            // isRequiredErrorMessage: L('ThisFieldIsRequired'),
            isRequiredErrorMessage: '',
            invalidInputErrorMessage: this.props.validationData && typeof this.props.validationData.invalidInputErrorMessage !== 'undefined' ? this.props.validationData.invalidInputErrorMessage
                                        : L('Invalid date format.'),
            isOutOfBoundsErrorMessage: this.props.validationData && typeof this.props.validationData.isOutOfBoundsErrorMessage !== 'undefined' ? this.props.validationData.isOutOfBoundsErrorMessage
                                        : `${L('Date must be between')} ${minDate.toLocaleDateString()}-${maxDate.toLocaleDateString()}`,
        };        
    }

    private mapValidationData(validationData: any): any {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['multiline', 'errorMessage', 'readOnly', 'disabled', 'minDate', 'maxDate'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'multiline') {
                    mappedValidationData['multiline'] = 'true';
                    mappedValidationData['rows'] = validationData[key];
                }
                
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }
                
                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }

                if((key === 'maxDate' || key === 'minDate') && moment(validationData[key]).isValid()) {
                    const date = new Date(validationData[key]);
                    mappedValidationData[key] = date;
                }

                if(key === 'minDate' && validationData[key] === 'TOMORROW') {
                    const today = new Date(Date.now());
                    mappedValidationData[key] = addDays(today, 1);
                }

                if(key === 'minDate' && validationData[key] === 'SUBTRACT_30_DAYS') {
                    const today = new Date(Date.now());
                    mappedValidationData[key] = addDays(today, -30);
                }
                
                if((key === 'maxDate' || key === 'minDate') && validationData[key] === 'TODAY') {
                    const today = new Date(Date.now());
                    mappedValidationData[key] = addDays(today, 0);
                }
                
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }

        return mappedValidationData;
    }

    public render(): JSX.Element {
        const { label, required, placeholder, ariaLabel, value, disabled, initial, shouldBeValidated, isDataLoaded, validationData, customLabelStyles, labelContainerCustomStyles, 
                customInputWidth, errorMessage } = this.props;
        const { firstDayOfWeek } = this.state;

        const datePickerStrings: IDatePickerStrings = this.getDatePickerStrings();
        
        let icons: any = <></>;
    
        if(!!this.props.tooltipText) {
            icons = <TooltipHost content={L(this.props.tooltipText)} id={`${this.props.id}_tooltip`} directionalHint={DirectionalHint.rightCenter}
                        calloutProps={{ gapSpace: 0 }} styles={{root: { display: 'inline-block' }}}
                    >
                        <Icon aria-describedby={`${this.props.id}__tooltip`} iconName={'Info'} className={gnInputIconStyles.icon} style={{cursor: 'default', marginTop: '4px'}} />
                    </TooltipHost>;
        }

        let isForValidate = shouldBeValidated === undefined ? true : shouldBeValidated;
        let mappedValidationData = this.mapValidationData(validationData);

        const datePicker = <DatePicker
            style={{width: customInputWidth ? customInputWidth : '300px', maxWidth: '300px', height: '33px'}}
            disabled={disabled}
            isRequired={required}
            firstDayOfWeek={firstDayOfWeek}
            strings={{
                ...datePickerStrings,
                invalidInputErrorMessage: errorMessage,
            }}
            placeholder={placeholder}
            ariaLabel={ariaLabel}
            minDate={isForValidate ? minDate : new Date(NaN)}
            maxDate={isForValidate ? maxDate : new Date(NaN)}
            value={value ? this._onParseDateFromString(value!) : initial}
            allowTextInput={true}
            onSelectDate={this._onSelectDate}
            formatDate={DatePickerBase.FormatDate}
            parseDateFromString={(dateStr: string) => this._onParseDateFromString(dateStr, false, true)}
            theme={myTheme}
            className={this.props.customClassNames && this.props.customClassNames}
            {...mappedValidationData}
        />;

        return label && label.length > 0 ?
            <LabelContainerComponent customStyles={labelContainerCustomStyles}> 
                <LabelComponent customStyles={customLabelStyles ? customLabelStyles : {}} label={label || ''} />
                <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                    {datePicker}
                </Shimmer>
                {icons}
            </LabelContainerComponent>
            : 
            <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                {datePicker}
                {icons}
            </Shimmer>;
    }

    private _onSelectDate = (date: Date | null | undefined): void => {
        date?.setHours(12);
        const parsedDate = this._onParseDateFromString(date, true);

        this.setState({ value: parsedDate });

        if (this.props.onChange && parsedDate){
            this.props.onChange(parsedDate!.toUTCString())
        }
    };

    static FormatDate = (date: Date | undefined): string => {
        if(typeof date === 'string') {
            date = new Date(date);
        }

        let dayNumber = (date?.getDate() || 0); 
        let day = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`;
        
        let monthNumber = (date?.getMonth() || 0) + 1;
        let month = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
        
        let yearNumber = date?.getFullYear() || 0;
        let year = `${yearNumber}`;
        
        let result = `${day}-${month}-${year}`;
        return result;
    };

    private _onParseDateFromString = (value: any, dateFromSelect: boolean = false, dateFromString: boolean = false): Date => {
        const momentDate = moment(value, dateFromSelect === true ? undefined : 'DD-MM-YYYY');
        const prevMomentDate = moment(this.state.value);

        const result = new Date(value);
        const formattedResult = new Date(momentDate.format('YYYY-MM-DD'));

        return this.props.doNotFormatResult === true || (dateFromSelect || (prevMomentDate.isValid() && momentDate.isSame(prevMomentDate, 'day')) || !moment(formattedResult).isValid()) 
                ? result : formattedResult;
    };
}