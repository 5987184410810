import * as React from 'react';
import { DirectionalHint, Dropdown, IDropdownOption, Icon, Shimmer, TooltipHost } from '@fluentui/react';
import Stores from '../../stores/storeIdentifier';
import { inject } from 'mobx-react';
import EventStore from '../../stores/eventStore';
import { LabelContainerComponent } from './labelContainerComponent';
import { LabelComponent } from './labelComponent';
import { getShimmerStyles } from '../../utils/stylesUtils';
import { L } from '../../lib/abpUtility';
import { isJsonString } from '../../utils/utils';
import { gnInputIconStyles } from '../../styles/gnInputIconStyles';

export interface IDropdownProps {
    id?: string | undefined;
    label?: string;
    options: IDropdownOption[];
    value?: string | number | undefined;
    onChange?: (value: string | number | undefined) => void;
    required?: boolean;
    eventStore?: EventStore;
    initialValue?: string | number | undefined;
    disabled?: boolean;
    placeholder?: string;
    customClassName?: any;
    isDataLoaded?: boolean;
    customDropdownWidth?: string;
    customLabelStyles?: any;
    validationData?: any;
    labelContainerCustomStyles?: any;
    tooltipText?: string;
    iconName?: string;
    iconOnClick?: (iconIndex?: number) => void;
}

export interface IDropdownState {
    value: string | number | undefined;
}

@inject(Stores.EventStore)
export class DropdownBase extends React.Component<IDropdownProps, IDropdownState> {
    constructor(props: IDropdownProps) {
        super(props)

        this.state = {
            value: ""
        }
    }

    private mapValidationData(validationData: any): any {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['multiline', 'errorMessage', 'readOnly', 'disabled'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'multiline') {
                    mappedValidationData['multiline'] = 'true';
                    mappedValidationData['rows'] = validationData[key];
                }
                
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }
                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }
        
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }
    
        return mappedValidationData;
    }

    // static getDerivedStateFromProps(props: any, state: any) {
    //     let val = props.initialValue || props.value;
    //     if (state.value !== val && props.initialValue !== props.value && props.onChange){
    //         try {
    //             props.onChange(val)
    //         }
    //         catch(err) {
    //             console.error(err)
    //         }
    //     }
    //     return { value: val };
    // }

    // componentDidMount() {
    //     try {
    //         if (this.props.value && this.props.value !== 0) {
    //             this.setState({ value: this.props.value })
    //             if (this.props.onChange)
    //                 this.props.onChange(this.props.value)
    //         }
    //     } catch (error) {

    //     }
    // }

    change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        this.setState({ value: option?.key });
        if (this.props.onChange)
            this.props.onChange(option?.key);
    }

    render() {
        const { label, options, value, required, eventStore, id, initialValue, disabled, placeholder, customClassName,
            isDataLoaded, customDropdownWidth, customLabelStyles, validationData, labelContainerCustomStyles } = this.props;
        
        let icons: any = <></>;
    
        if(!!this.props.tooltipText) {
            icons = <TooltipHost content={L(this.props.tooltipText)} id={`${this.props.id}_tooltip`} directionalHint={DirectionalHint.rightCenter}
                        calloutProps={{ gapSpace: 0 }} styles={{root: { display: 'inline-block' }}}
                    >
                        <Icon aria-describedby={`${this.props.id}__tooltip`} iconName={'Info'} className={gnInputIconStyles.icon} style={{cursor: 'default', marginTop: '4px'}} />
                    </TooltipHost>;
        }

        if(this.props.iconName) {
            if(!isJsonString(this.props.iconName)) {
                icons = <Icon iconName={this.props.iconName} className={gnInputIconStyles.icon} onClick={() => this.props.iconOnClick!()} />;
            } else {
                let parsedJsonString: any = JSON.parse(this.props.iconName);
                if(parsedJsonString && Array.isArray(parsedJsonString)) {
                    icons = parsedJsonString.map((iconName: string, index: number) => {
                            return <Icon iconName={iconName} className={gnInputIconStyles.icon} onClick={() => this.props.iconOnClick!(index)} /> 
                        });
                }
            }
        }

        const selectedKey = initialValue || value;
        let mappedValidationData = this.mapValidationData(validationData);
        
        const dropdown = <Dropdown
            className={customClassName}
            style={{width: customDropdownWidth ? customDropdownWidth : "300px"}}
            errorMessage={eventStore!.getErrorMessageFor(id)} 
            disabled={disabled} 
            required={required} 
            selectedKey={selectedKey} 
            options={options} 
            onChange={this.change} 
            placeholder= {placeholder}
            {...mappedValidationData}
        />;

        return label && label.length > 0 ?
            <LabelContainerComponent customStyles={labelContainerCustomStyles}>
                <LabelComponent customStyles={customLabelStyles ? customLabelStyles : {}} label={label || ''} />
                <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                    { dropdown }
                </Shimmer>
                {icons}
            </LabelContainerComponent>
            : 
            <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                { dropdown } 
                {icons}
            </Shimmer>;
    }
}