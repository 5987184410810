import { authStore } from "../stores/authenticationStore";
import jwt_decode from "jwt-decode";
import { isGranted } from "../lib/abpUtility";
import { RouterPath } from "../components/Router/router.config";
import { AppConsts } from "../lib/appconst";

export function isOnAllowedPath(routePath?: string): boolean {
    const allowedPathNames: string[] = ['/', '/user/login', '/account/resetpassword', '/account/confirmemail', 
                                        '/account/terms-of-use', '/account/privacy-policy'];
                                        
    return allowedPathNames.includes(routePath || window.location.pathname);
}

export function isConfigForAG(passedAppConsts?: any): boolean {
    const AppConstsClone: any = !!passedAppConsts ? passedAppConsts : AppConsts;
    
    if(!AppConstsClone || !AppConstsClone.isConfigForAG || typeof AppConstsClone.isConfigForAG !== 'boolean' || AppConstsClone.isConfigForAG === false) {
        return false;
    } else {
        return true;
    }
}

export function isConfigForUAT(passedAppConsts?: any): boolean {
    const AppConstsClone: any = !!passedAppConsts ? passedAppConsts : AppConsts;
    
    if(!AppConstsClone || !AppConstsClone.isConfigForUAT || typeof AppConstsClone.isConfigForUAT !== 'boolean' || AppConstsClone.isConfigForUAT === false) {
        return false;
    } else {
        return true;
    }
}

export function isConfigForProduction(passedAppConsts?: any): boolean {
    const AppConstsClone: any = !!passedAppConsts ? passedAppConsts : AppConsts;
    
    if(!AppConstsClone || !AppConstsClone.isConfigForProduction || typeof AppConstsClone.isConfigForProduction !== 'boolean' || AppConstsClone.isConfigForProduction === false) {
        return false;
    } else {
        return true;
    }
}

export function hasPermissionsToPage(routePath?: string): boolean {
    if(isOnAllowedPath(routePath)) return true;

    switch(routePath || window.location.pathname) {
        // case '/role':
        // case '/role-list':
        //     return isGranted('Pages.Roles');
        // case '/user':
        // case '/user-list':
        //     return isGranted('Pages.Users');
        case `/${RouterPath.Customer}`:
        case `/${RouterPath.CustomerList}`:
            return isGranted('Client.View.A') || isGranted('Client.View.S') || isGranted('Client.View.O');
        case `/${RouterPath.AgentClaimList}`:
            return isGranted('AgentClaim.View.A') || isGranted('AgentClaim.View.S') || isGranted('AgentClaim.View.O');
        case `/${RouterPath.ApkList}`:
            return isGranted('Apk.View.A') || isGranted('Apk.View.S') || isGranted('Apk.View.O');
        case `/${RouterPath.CalculationList}`:
            return isGranted('PolicyCalculation.View.A') || isGranted('PolicyCalculation.View.S') || isGranted('PolicyCalculation.View.O');
        case `/${RouterPath.InsurancePolicyList}`:
            return isGranted('Policy.View.A') || isGranted('Policy.View.S') || isGranted('Policy.View.O');
        case `/${RouterPath.VehicleList}`:
            return isGranted('Vehicle.View.A') || isGranted('Vehicle.View.S') || isGranted('Vehicle.View.O');
        case `/${RouterPath.ClaimList}`:
            return isGranted('Claim.View.A') || isGranted('Claim.View.S') || isGranted('Claim.View.O');
        case `/${RouterPath.RegisteredUser}`:
        case `/${RouterPath.UserList}`:
            return isGranted('User.View.A') || isGranted('User.View.S') || isGranted('User.View.O');
        case `/${RouterPath.RoleList}`:
            return isGranted('Role.View.A') || isGranted('Role.View.S') || isGranted('Role.View.O');
        case `/${RouterPath.PatternList}`:
            return isGranted('Pattern.View.A') || isGranted('Pattern.View.S') || isGranted('Pattern.View.O');
        case `/${RouterPath.RatingList}`:
            return isGranted('Rating.View.A') || isGranted('Rating.View.S') || isGranted('Rating.View.O');
        case `/${RouterPath.CommunityList}`:
            return isGranted('Community.View.A') || isGranted('Community.View.S') || isGranted('Community.View.O');
        case `/${RouterPath.TestSetList}`:
            return isGranted('TestSet.View.A') || isGranted('TestSet.View.S') || isGranted('TestSet.View.O');
        case `/${RouterPath.CustomerGroupList}`:
            return isGranted('CustomerGroup.View.A') || isGranted('CustomerGroup.View.S') || isGranted('CustomerGroup.View.O');
        case `/${RouterPath.LoyaltyPointsHistoryList}`:
            return isGranted('LoyaltyPointsHistory.View.A') || isGranted('LoyaltyPointsHistory.View.S') || isGranted('LoyaltyPointsHistory.View.O');
        case `/${RouterPath.ProductList}`:
            return isGranted('Product.View.A') || isGranted('Product.View.S') || isGranted('Product.View.O');
        case `/${RouterPath.InsuranceCompanyList}`:
            return isGranted('Insurer.View.A') || isGranted('Insurer.View.S') || isGranted('Insurer.View.O');
        case `/${RouterPath.OrderList}`:
            return isGranted('Order.View.A') || isGranted('Order.View.S') || isGranted('Order.View.O');
        case `/${RouterPath.Settings}`:
            return isGranted('GlobalConfigurations.View.A') || isGranted('GlobalConfigurations.View.S') || isGranted('GlobalConfigurations.View.O');
        case `/${RouterPath.Contest}`:
        case `/${RouterPath.ContestList}`:
            return isGranted('Contest.View.A') || isGranted('Contest.View.S') || isGranted('Contest.View.O');
        default: 
            return true;
    }
}

export function isUserLoggedIn() {          
    if(!authStore.isLoggedIn && !isOnAllowedPath()) {
        authStore.logout();
        window.location.href = '/';
    }
}

export function jwtCheckIfExpired(token: string): boolean {
    const decodedToken: any = jwt_decode(token);

    if(decodedToken.exp && Date.now() >= decodedToken.exp * 1000) {
        return true;
    } else {
        return false;
    }
}